<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M9 6C9 5.44772 9.44771 5 10 5H14C14.5523 5 15 5.44772 15 6C15 6.55228 14.5523 7 14 7H10C9.44771 7 9 6.55228 9 6Z"
    />
    <path
      d="M10 9C9.44771 9 9 9.44771 9 10C9 10.5523 9.44771 11 10 11H14C14.5523 11 15 10.5523 15 10C15 9.44771 14.5523 9 14 9H10Z"
    />
    <path
      d="M9 14C9 13.4477 9.44771 13 10 13H14C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15H10C9.44771 15 9 14.5523 9 14Z"
    />
    <path
      d="M10 17C9.44771 17 9 17.4477 9 18C9 18.5523 9.44771 19 10 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H10Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 8V4C19 3.20435 18.6839 2.44129 18.1213 1.87868C17.5587 1.31607 16.7956 1 16 1H8C7.20435 1 6.44129 1.31607 5.87868 1.87868C5.31607 2.44129 5 3.20435 5 4V11H4C3.20435 11 2.44129 11.3161 1.87868 11.8787C1.31607 12.4413 1 13.2044 1 14V20C1 20.7957 1.31607 21.5587 1.87868 22.1213C2.44129 22.6839 3.20435 23 4 23H20C20.7957 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7957 23 20V11C23 10.2043 22.6839 9.44129 22.1213 8.87868C21.5587 8.31607 20.7957 8 20 8H19ZM8 3C7.73478 3 7.48043 3.10536 7.29289 3.29289C7.10536 3.48043 7 3.73478 7 4V21H17V4C17 3.73478 16.8946 3.48043 16.7071 3.29289C16.5196 3.10536 16.2652 3 16 3H8ZM19 10V21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V11C21 10.7348 20.8946 10.4804 20.7071 10.2929C20.5196 10.1054 20.2652 10 20 10H19ZM4 21H5V13H4C3.73478 13 3.48043 13.1054 3.29289 13.2929C3.10536 13.4804 3 13.7348 3 14V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21Z"
    />
  </svg>
</template>
